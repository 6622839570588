<template>
        <section class="about-section bg-light" id="about">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5 justify-content-center text-center">
                    <div class="col-lg-8">
                        <h2 class="text-black mb-4">About</h2>
                    </div>
                </div>
                <div class="row gx-4 gx-lg-5 text-left justify-content-center">
                    <div class="col-lg-8">
                        <p class="text-black">
                            Founder and principal consultant <a href="https://www.linkedin.com/in/stevendovenz" target="_blank">Steven Dove</a> formed New Axis out of a passion to help companies that rely on rapid and effective software development thrive.  
                            <br />
                            <br />
                            Steven has been developing software professionally since the 90’s and has experience leading transformative change from his time as Head of Technology at <a href="https://serko.com" target="_blank">Serko</a> and as Chief Technology Officer for <a href="https://flowingly.io" target="_blank">Flowingly</a>.
                        </p>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import image from "@/assets/img/profile_image.jpeg"

export default {
  data: function () {
      return {
        profilepic: image,
      }
  },      
  name: "About"
};
</script>



<style scoped>
</style>

<template>
  <div>
    <Masthead />
    <Services />
    <About />
    <Signup />
  </div>
</template> 

<script>
import Masthead from "@/components/Masthead.vue";
import Services from "@/components/Services.vue";
import About from "@/components/About.vue";
import Signup from "@/components/Signup.vue";


export default {
  name: "Blank",
  components: {
    Masthead,
    Services,
    About,
    Signup
  }
};
</script>

<style scoped>
</style>

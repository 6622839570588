<template>
    <header class="masthead"> 
        <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
            <div class="row d-flex justify-content-center">
                <div class="text-center">
                    <h1 class="mx-auto my-0 text-uppercase">Innovation at Pace</h1>
                    <h2 class="text-white-50 mx-auto mt-2 mb-5">Practical experience to enable true agility for your business</h2>
                    <div class="text-white-50 mx-auto mt-2 mb-5">When it comes to software development, safe and fast is possible, in fact they go better together.  If that's not happening for your business you're in the right place.</div>
                    <div class="text-white-50 mx-auto mt-2 mb-5">New Axis provides technical consulting and advisory services to companies where delivering software quickly and safely is essential to their strategy.</div>                    
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
  name: "Masthead"
};
</script>


<style scoped>
</style>

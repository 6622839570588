<template>
  <footer class="footer bg-black small text-center text-white-50">
      <div class="container px-4 px-lg-5">&copy; New Axis Limited 2023</div>
      <div class="container px-4 px-lg-5">Auckland, New Zealand</div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>


<style scoped>
.footer {
  background-color: #1d1d1d;
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.title {
  color: #fff;
}

.description {
  color: #fff;
}

.footerlink,
.footerlink:hover {
  color: #fff;
}
</style>

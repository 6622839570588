var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"signup-section",attrs:{"id":"signup"}},[_c('div',{staticClass:"container px-4 px-lg-5"},[_c('div',{staticClass:"row gx-4 gx-lg-5"},[_c('div',{staticClass:"col-md-10 col-lg-8 mx-auto text-center"},[_c('h2',{staticClass:"text-white mb-5"},[_vm._v("Contact")]),(!_vm.enquirySubmitted)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form-signup",attrs:{"id":"contactForm","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.checkForm)}}},[_c('div',{staticClass:"row input-group-newsletter"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-control",class:classes,attrs:{"id":"name","type":"text","placeholder":"Enter your name...","aria-label":"Enter your name...","disabled":_vm.enquirySubmitting},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('div',{staticClass:"invalid-tooltip"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('br'),_c('div',{staticClass:"row input-group-newsletter"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.emailAddress),expression:"emailAddress"}],staticClass:"form-control",class:classes,attrs:{"id":"emailAddress","type":"email","placeholder":"Enter email address...","aria-label":"Enter email address...","disabled":_vm.enquirySubmitting},domProps:{"value":(_vm.emailAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.emailAddress=$event.target.value}}}),_c('div',{staticClass:"invalid-tooltip"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('br'),_c('div',{staticClass:"row input-group-newsletter align-items-end"},[_c('div',{staticClass:"col"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-control",attrs:{"id":"message","rows":"5","placeholder":"Enter a message...","aria-label":"Enter a message...","disabled":_vm.enquirySubmitting},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}})]),_c('div',{staticClass:"col-auto"},[_c('button',{staticClass:"btn btn-primary",attrs:{"id":"submitButton","type":"submit","disabled":_vm.enquirySubmitting}},[_vm._v(" Submit ")])])])])]}}],null,false,660634888)}):_vm._e(),_c('br'),(_vm.enquirySubmitted)?_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_vm._v(" Thanks for getting in touch - we'll respond soon. ")]):_vm._e(),(_vm.enquiryFailed)?_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v(" Something went wrong there - please try again or feel free to connect with any of "),_c('a',{attrs:{"href":"https://www.linkedin.com/company/new-axis/people"}},[_vm._v("our people")])]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
        <section class="about-section bg-light" id="services">
            <div class="container px-4 px-lg-5">
                <div class="row gx-4 gx-lg-5 justify-content-center text-center">
                    <div class="col-lg-8">
                        <h2 class="text-black mb-4">Services</h2>
                    </div>
                </div>
                <div class="row gx-4 gx-lg-5 text-left justify-content-center">
                    <div class="col-lg-8">
                        <p class="text-black">
                            If you'd like a hand steering your team towards high performance let's <a href="#signup">talk</a>.
                            <br/>
                            <br/>
                            <i>"High performers understand that they don’t have to trade speed for stability or vice versa, because by building quality in they get both"</i>
                            <br/>
                            <a href="https://www.amazon.com.au/Accelerate-Software-Performing-Technology-Organizations/dp/1942788339" target="_blank">Accelerate: The Science of Lean Software and DevOps</a>
                            <br/>
                            <br/>                            
                            In a globally competitive and digitally connected world your business can't afford to slow down, nor can you afford to go down. 
                            <br/>
                            <br/>
                            The research is clear about what 'high performance' looks like in a software powered business, however, knowing how to get there isn't easy. You likely have pressure from all sides to deliver innovation, a 'mature' system that resists change, and engineering staff that are stretched.
                            <br/>
                            <br/>
                            We can help with:
                            <br/>
                            <br/>
                            ● Short- or long-term tech leadership as an interim or virtual CTO
                            <br/>
                            ● An evaluation of the current overall health of the technology function
                            <br/>
                            ● Ongoing advising of the current engineering leadership
                        </p>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
export default {
  name: "Services"
};
</script>


<style scoped>
</style>

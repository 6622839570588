import App from "@/App.vue";
import BootstrapVue from "bootstrap-vue";
import router from "@/router";
import Vue from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.min.css";
import './assets/styles.css'
import { configure } from 'vee-validate';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);


// Sets the options.
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  },
  mode: 'eager'
});


new Vue({
  router,
  render: h => h(App)
}).$mount("#app");


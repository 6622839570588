<template>
  <section class="signup-section" id="signup">
    <div class="container px-4 px-lg-5">
      <div class="row gx-4 gx-lg-5">
        <div class="col-md-10 col-lg-8 mx-auto text-center">
          <h2 class="text-white mb-5">Contact</h2>
          <!-- to get an API token!-->
          <ValidationObserver
            v-slot="{ handleSubmit }"
            v-if="!enquirySubmitted"
          >
            <form
              class="form-signup"
              id="contactForm"
              @submit.prevent="handleSubmit(checkForm)"
              novalidate
            >
              <div class="row input-group-newsletter">
                <div class="col">
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    rules="required"
                  >
                    <input
                      class="form-control"
                      :class="classes"
                      id="name"
                      v-model="name"
                      type="text"
                      placeholder="Enter your name..."
                      aria-label="Enter your name..."
                      :disabled="enquirySubmitting"
                    />
                    <div class="invalid-tooltip">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <br />
              <!-- Email address input-->
              <div class="row input-group-newsletter">
                <div class="col">
                  <ValidationProvider
                    v-slot="{ errors, classes }"
                    rules="required|email"
                  >
                    <input
                      class="form-control"
                      :class="classes"
                      id="emailAddress"
                      v-model="emailAddress"
                      type="email"
                      placeholder="Enter email address..."
                      aria-label="Enter email address..."
                      :disabled="enquirySubmitting"
                    />
                    <div class="invalid-tooltip">{{ errors[0] }}</div>
                  </ValidationProvider>
                </div>
              </div>
              <br />
              <div class="row input-group-newsletter align-items-end">
                <div class="col">
                  <textarea
                    class="form-control"
                    id="message"
                    v-model="message"
                    rows="5"
                    placeholder="Enter a message..."
                    aria-label="Enter a message..."
                    :disabled="enquirySubmitting"
                  ></textarea>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-primary"
                    id="submitButton"
                    type="submit"
                    :disabled="enquirySubmitting"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
          <br />
          <!-- Submit success message-->
          <div class="alert alert-success" role="alert" v-if="enquirySubmitted">
            Thanks for getting in touch - we'll respond soon.
          </div>
          <!-- Submit error message-->
          <div class="alert alert-danger" role="alert" v-if="enquiryFailed">
            Something went wrong there - please try again or feel free to
            connect with any of
            <a href="https://www.linkedin.com/company/new-axis/people"
              >our people</a
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} is required.",
});
extend("email", email);

//var apiUrl = process.env.NODE_ENV == "development" ? 'https://localhost:5001/api/enquiries' : '/api/enquiries'
//var apiUrl = '/api/enquiries'
var apiUrl =
  "https://prod-14.australiaeast.logic.azure.com:443/workflows/fc59586d802043ab9fa8760238c2ef62/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=NkczKWvB20gsYoXJinxzQscPf1c0Nqc6odnLQn4mR_4";

async function postData(url = "", data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response;
}

export default {
  name: "Signup",
  errors: [],
  data: function () {
    return {
      name: "",
      emailAddress: "",
      message: "",
      enquirySubmitted: false,
      enquiryFailed: false,
      enquirySubmitting: false,
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    checkForm: function () {
      this.enquirySubmitting = true;
      this.enquiryFailed = false;
      postData(apiUrl, {
        name: this.name,
        email: this.emailAddress,
        message: this.message,
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response;
        })
        .then((response) => {
          console.log(response);
          this.enquirySubmitted = true;
        })
        .catch((error) => {
          console.error(
            "There has been a problem with your fetch operation:",
            error
          );
          this.enquiryFailed = true;
          this.enquirySubmitting = false;
        });
    },
  },
};
</script>
